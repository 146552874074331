import type { NextPage } from 'next';
import { useTranslation } from 'react-i18next';

const Home: NextPage = () => {
  const { t } = useTranslation();

  return (
    <main className="container center">
      <div className="container-content simpli">
        <div className="row">
          <div className="content">
            <h1>
              Jellyworkz <br /> {t('tagline:text')}
            </h1>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
